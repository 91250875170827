<template>
  <div>
    <CRow>
      <CCol>
        <CCard>
          <CCardBody class="HeightWidgets">
            <div>
              <h3>
                <CIcon name="cil-people" size="xl" :class="'Primary'" />
                {{ counts["clients"] }}
              </h3>
              <h5>Your clients</h5>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody class="HeightWidgets">
            <div>
              <h3>
                <CIcon name="cil-file" size="xl" :class="'Primary'" />
                {{ counts["projects"] }}
              </h3>
              <h5>Your projects</h5>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody class="HeightWidgets">
            <div>
              <h3>
                <CIcon name="cil-list" size="xl" :class="'Primary'" />
                {{ counts["history"] }}
              </h3>
              <h5>Total ingestions</h5>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
      <CCol>
        <CCard>
          <CCardBody class="HeightWidgets">
            <div>
              <h3>
                <CIcon name="cil-list" size="xl" :class="'Primary'" />
                {{ counts["person_count"] }}
              </h3>
              <h5>Your ingestions</h5>
            </div>
          </CCardBody>
        </CCard>
      </CCol>
    </CRow>
    <CCard>
      <CCardBody style="display: flex; justify-content: center">
        <CButton href="new-upload" class="mazarsButton">Add new data</CButton>
        <CButton href="previous-uploads" class="mazarsButton"
          >See ingestions history</CButton
        >
      </CCardBody>
    </CCard>
  </div>
</template>

<script>
import { ListsService } from "@/services/lists";

export default {
  name: "Home",
  data() {
    return {
      counts: {},
    };
  },
  methods: {
    async getCount() {
      ["history", "clients", "projects"].map(async (coll) => {
        var { data, res } = await ListsService.get_count({ collection: coll });
        if (res.status !== 200) {
          return alert("Error getting selects from db");
        }
        if (data.success) {
          if (data["person_count"]) {
            this.counts["person_count"] = data.person_count;
          }
          this.counts[coll] = data.count;
          this.$forceUpdate();
        }
      });
    },
  },

  created() {
    this.getCount();
  },
};
</script>

<style scoped lang="scss">
.mazarsButton {
  align-items: center;
  background-color: #0071ce;
  border: 1px solid #0071ce;
  border-radius: 0;
  border-bottom-left-radius: 1.75rem;
  color: #fff;
  cursor: pointer;
  display: inline-flex;
  font-size: 1rem;
  font-weight: 500;
  justify-content: center;
  line-height: 1;
  max-width: 100%;
  min-height: 3rem;
  min-width: 300px;
  overflow: hidden;
  padding: 0.75rem 1.5rem;
  position: relative;
  text-decoration: none !important;
  transition: all 0.3s;
  vertical-align: middle;
  margin: 30px;
}
</style>
